body {
  margin: 0;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: #1A5319;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Background{
  background-color: #D6EFD8;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainBackground{
  background-color: rgb(63, 58, 51);
  width: 85%;
  height: 85%;
  border: 5px solid #1A5319;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.MainBackground button{
  background-color: #80AF81 ;
}
.MainBackground button:hover{
  background-color: #777e78 !important;
}
.corneruserauth{
  position: absolute;
  height: 50%;
  width: 50%;
  top: 5px;
  left: 30px;
}
.MainAuth{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: 75%;
}
.Header{
  margin-top: 0%;
  font-size: 1.6em;
  font-weight: 900;
}
.Auth{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 30px;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.Auth button{
  width: 200px;
  height: 40px;
  border: none;
  background-color: #80AF81;
  padding: 5px;
  border-radius: 8px;
  font-weight: bolder;
  font-size: 1em;
  color: #1A5319;
}
.Auth button:hover{
  transition: all 0.5s;
  background-color: #1A5319;
  color: #80AF81;
}
.SideTab{
  background-color: #80AF81;
  width: 4%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 2px solid #1A5319;
  text-wrap:balance;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 86.5%;
}
.SideTab button{
  width: 40px;
  height: 40px;
  font-weight: 900;
  border-radius: 8px;
  background-color: #80AF81 ;
  color: #D6EFD8;
  border: none;
}
.SideTab button:hover{
  transition: all .5s;
  background-color: rgb(209, 155, 155);
}
.SideTabBtn1{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
}
.SideTabBtn2{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2em;
}
.RoomsTab{
  background-color: #80AF81;
  width: 28%;
  height: 100%;
  text-align: center;
  border-right: 2px solid #1A5319;
}
.RoomsTabHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px;
  background-color: #80AF81;
  border-bottom: 2px solid #1A5319;
  height: 7.5%;
}
.RoomsTabHeader button{
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: none;
  background-color: #80AF81 ;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.6em;
}
.RoomsTabHeader button:hover{
  transition: all .5s;
  background-color: rgb(209, 155, 155);
}
.RoomsTabHeaderSettings{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #80AF81;
  border-bottom: 2px solid #1A5319;
  height: 7.5%;
}
.ActiveRooms{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 92.5%;
  overflow: scroll;
  overflow-x: hidden;
}
.RoomsListDiv{
  width: 100%;
  height: 8%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #1A5319;
  background-color: #508D4E;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1A5319;
  font-size: 1.2em;
}
.RoomsListDiv:hover{
  color: #D6EFD8;
}
.RoomListInsideDiv{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RoomListInsideDivName{
  width: 100%;
}
.ActiveRoomsNoActive{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center; /* Ensures text is centered */
}
.ActiveRoomsNoActive h2{
  transform: translateY(-35px);
  color: rgba(122, 111, 111, 0.349);
  font-style: italic;
}
.LeaveRoomHeader{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LeaveRoomHeaderTab{
  width: 100%;
  height: 59px;
  background-color: #80AF81;
  border-bottom: 2px solid #1A5319;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LeaveRoomButtonSideTab{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  height: 100%;
  color: #c9d8c9;
}
.ChatTab{
  background-color: #508D4E;
  width: 68%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ChatTabHeader {
  background-color: #80AF81;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Reduces width to add space */
  height: 7.5%;
  border-top-right-radius: 10px;
  border-bottom: 2px solid #1A5319;
}
.ChatTabHeader h1{
  margin-left: 10px;
}
.ChatTabHeader button{
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: #80AF81;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.6em;
  margin-right: 10px; 
}
.ChatTabHeader button:hover{
  transition: all .5s;
  background-color: rgb(209, 155, 155);
}
.ChatTabHeaderButtons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ChatTabHeaderButtons p{
  font-size: 1.3em;
  font-weight: bold;
  color: #d6efd8d8;
}
.ChatTabMsgs{
  width:100%;
  height: 84.5%;
  overflow: scroll;
  overflow-x: hidden;
  color: #D6EFD8;
}
.TextMSG{
  margin-left: 14px;
  font-size: 1.3em;
  font-weight: bolder;
  word-break: break-all;
  margin-right: 14px;
}
.ChatTabMsgsInput{
  background-color: #80AF81;
  display: flex;
  align-items: center;
  width: 100%;
  height: 8%;
  gap: 18px;
  border-bottom-right-radius: 10px;
  border-top: 2px solid #1A5319;
}
.ChatTabMsgsInput button{
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 8px;
  border: none;
  background-color: #80AF81;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.6em;
}
.ChatTabMsgsInput button:hover{
  transition: all .5s;
  background-color: rgb(209, 155, 155);
}
.ChatTabMsgsInput input{
  width: 90%;
  height: 40%;
  padding: 5px;
  border-radius: 8px;
  margin-left: 20px;
  border: none;
  background-color: #D6EFD8;
}
.ChatTabNoRoomActive h2{
  color: rgba(255, 255, 255, 0.349);
  font-style: italic;
}
.RoomCreationPopUpBG{
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 100000;
  background-color: rgba(184, 154, 115, 0.555);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.RoomCreationPopUp {
  background-color: #80AF81;
  width: 30%;
  height: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 5px solid #1A5319;
  border-radius: 16px
}
.RoomCreationPopUpHeader{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 16px;
  font-size: 1.5em;
  border-top-right-radius: 16px;
}
.PrivateRoomPopUpHeader{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 16px;
  font-size: 1.5em;
  border-top-right-radius: 16px;
}
.PrivateRoomPopUpEnterPass{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 70%;
}
.PrivateRoomPopUpHeader h2{
  font-size: 1em;
}
.RoomCreationPopUpHeader h2{
  font-size: 1em;
}
.RoomCreationPopUpHeader button{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  border-radius: 5px;
  background-color: #80AF81 ;
  border: none;
  position: absolute;
  margin-left: 400px;
}
.PrivateRoomPopUpHeader button{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  border-radius: 5px;
  background-color: #80AF81 ;
  border: none;
  position: absolute;
  margin-left: 400px;
}
.RoomCreationPopUpHeader button:hover{
  background-color: rgb(209, 182, 147);
}
.RoomCreationPopUpData {
  width: 60%;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure even spacing between the items */
  align-items: flex-start; /* Align items to the left */
  padding: 20px; /* Optional: Add padding for spacing */
  gap: 10px;
}

.RoomCreationPopUpInput, 
.RoomCreationPopUpPasswordInput, 
.RoomCreationPopUpButton {
  width: 100%; /* Make the children take full width */
}

.RoomCreationPopUpInput{
  display: flex;
  align-items: center;
}
.RoomCreationPopUpPasswordInput{
  display: flex;
  align-items: center;
}
.RoomCreationPopUpInput label, 
.RoomCreationPopUpPasswordInput label {
  font-weight: bolder;
  margin-right: 10px;
}

.RoomCreationPopUpInput input, 
.RoomCreationPopUpPasswordInput input {
  width: 100%; /* Make the input field take the full width */
  padding: 5px; /* Optional: Add padding for input fields */
  border: none;
  border-radius: 6px;
  font-weight: bolder;
  margin-top: 30px;
  letter-spacing: 1px;
}

.RoomCreationPopUpInput input{
  background-color: #D6EFD8;
}
.RoomCreationPopUpPasswordInput input{
  background-color: #D6EFD8;
}

.RoomCreationPopUpButton button {
  width: auto; /* Optional: Adjust button width */
  align-self: flex-start; /* Ensure the button stays aligned left */
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  border: none;
  border-radius: 7px;
}
.RoomCreationPopUpButton button:hover{
  background: linear-gradient(to right,  rgb(88, 86, 82) 48.5%,  rgb(168, 155, 136) 48.5%);
}
.RoomCreationPopUpSubmitBtn{
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PrivateRoomPopUpEnterPass button{
  width: 100px;
  height: 20%;
  margin-bottom: 20px;
  border-radius: 12px;
  border: none;
  font-size: 1.2em;
  font-weight: bolder;
  background-color: #1A5319;
  color: #D6EFD8;
}
.RoomCreationPopUpSubmitBtn button{
  width: 100px;
  height: 80%;
  margin-bottom: 20px;
  border-radius: 12px;
  border: none;
  font-size: 1.2em;
  font-weight: bolder;
  background-color: #1A5319;
  color: #D6EFD8;
}
.PrivateRoomEnterPopUpBG{
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 100000;
  background-color: rgba(184, 154, 115, 0.555);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.PrivateRoomPopUp{
  background-color: rgb(184, 154, 115);
  width: 30%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 5px solid rgba(0, 0, 0, 0.212);
  border-radius: 16px
}

.exitPass{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  border-radius: 5px;
  background-color:  rgb(184, 154, 115);
  border: none;
  position: absolute;
  margin-left: 520px;
}
.exitPass:hover{
  background-color: rgb(209, 182, 147);
}
.PrivateRoomPopUp input{
  transform: translateY(-15px);
  width: 200px;
  height: 20px;
  padding: 3px;
  border: none;
  border-radius: 8px;
}
.RoomCreationPopUp input{
  transform: translateY(-15px);
  width: 200px;
  height: 20px;
  padding: 3px;
  border: none;
  border-radius: 8px;
}
.submitPass{
  width: 100px;
  height: 20%;
  margin-bottom: 20px;
  border-radius: 12px;
  border: none;
  font-size: 1.2em;
  font-weight: bolder;
  background-color: #3eca3b;
  color: #D6EFD8;
}
.submitPass:hover{
  background-color: rgb(155, 141, 121);
}
.UpdateRoomPopUpBG{
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 100000;
  background-color: rgba(184, 154, 115, 0.555);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.UpdateRoomPopU{
  background-color: rgb(184, 154, 115);
  width: 30%;
  height: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 5px solid rgba(0, 0, 0, 0.212);
  border-radius: 16px
}
.UpdateRoomPopUpHeader{
  transform: translateY(-30px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.exitUpdatePass{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  border-radius: 5px;
  background-color:  rgb(184, 154, 115);
  border: none;
  position: absolute;
  margin-left: 520px;
}
.exitUpdatePass:hover{
  background-color: rgb(209, 182, 147);
}
.UpdateRoomPopU input{
  transform: translateY(-15px);
  width: 200px;
  height: 20px;
  padding: 3px;
  border: none;
  border-radius: 8px;
}
.saveUpdates{
  width: 80px;
  height: 20px;
  border-radius: 12px;
  border: none;
  font-size: 1.2em;
  font-weight: bolder;
  background-color: rgb(219, 198, 169);
}
.saveUpdates:hover{
  background-color: rgb(155, 141, 121);
}
.DeleteRoomBtn{
  width: 140px;
  height: 30px;
  border-radius: 12px;
  border: none;
  font-size: 1.2em;
  font-weight: bolder;
  background-color: rgb(219, 198, 169);
}
.DeleteRoomBtn:hover{
  background-color: rgb(155, 141, 121);
}
.UpdateROomBtns{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.SettingsUserDisplay{
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 5%;
}
.settingsusername{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}
.settingsusermail{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}
.LogOutButton{
  width: 100px;
  height: 35px;
  margin-top: 5%;
}
.LogOutButton button{
  width: 110px;
  height: 35px;
  font-size: 1.5em;
  font-weight: bolder;
  border: none;
  border-radius: 4px;
  background-color: #80AF81;
  color: #D6EFD8;
}
.LogOutButton button:hover{
  transition: all 0.5s;
  margin-left: 10px;
}
@media (max-width: 1600px){
  .LeaveRoomHeaderTab{
    height: 51.5px;
  }
}
@media (max-width: 1460px){
  .RoomCreationPopUpHeader button{
    margin-left: 350px;
  }
  .PrivateRoomPopUpHeader button{
    margin-left: 300px;
  }
  .settingsusermail h2{
    font-size: 1.3em;
    word-break: break-all;
  }
}
@media (max-width: 1300px) {
  .RoomCreationPopUpHeader button{
    margin-left: 250px;
  }
}
@media (max-width: 1200px) {
  .RoomsTabHeader h1{
    font-size: 1.8em;
    transition: all 0.5s ease;
  }
  .LeaveRoomHeaderTab h1{
    font-size: 1.8em;
    transition: all 0.5s ease;
  }
  .RoomListInsideDivName h2{
    transition: all 0.5s ease;
    font-size: 1.4em;
  }
  .settingsusermail h2{
    font-size: 1.1em;
    word-break: break-all;
  }
}
@media (max-width: 1145px){
  .SideTab {
    gap: 87%;
    transition: all 0.5s ease;
  }
  .SideTab button{
    width: 30px;
    height: 30px;
    transition: all 0.5s ease;
  }
  .DeleteRoomBtn{
    width: 120px;
  }
  .PrivateRoomPopUpHeader button{
    margin-left: 250px;
  }
  .RoomCreationPopUp{
    height: 50%;
  }
  .RoomCreationPopUpInput{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .RoomCreationPopUpPasswordInput{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media (max-width: 950px) {
  .RoomsTabHeader h1{
    transition: all 0.5s ease;
    font-size: 1.4em;
  }
  .LeaveRoomHeaderTab h1{
    font-size: 1.4em;
    transition: all 0.5s ease;
  }
  .RoomCreationPopUpHeader h2{
    font-size: .9em;
  }
  .RoomCreationPopUpHeader button{
    margin-left: 200px;
  }
  .PrivateRoomPopUpHeader button{
    margin-left: 200px;
  }
  .PrivateRoomPopUpHeader h2{
    font-size: .7em;
  }
  .ChatTabHeader h1{
    font-size: 1.8em;
  }
  .ChatTabHeader p{
    font-size: 1.2em;
  }
  .RoomListInsideDivName h2{
    transition: all 0.5s ease;
    font-size: 1em;
    font-weight: bolder;
  }
  .RoomsTabHeaderSettings h1{
    font-size: 1.8em;
    transition: all 0.5s ease;
  }
  .settingsusername h2{
    font-size: 1.3em;
  }
}
@media (max-width: 845px){
  .SideTab {
    gap: 88.5%;
    transition: all 0.5s ease;
  }
  .SideTab button{
    width: 20px;
    height: 20px;
    transition: all 0.5s ease;
  }
  .SideTab button img{
    transition: all 0.5s ease;
    width: 25px;
  }
  .DeleteRoomBtn{
    width: 100px;
  }
}
@media (max-width: 740px) {
  .RoomsTabHeader h1{
    transition: all 0.5s ease;
    font-size: 1em;
  }
  .LeaveRoomHeaderTab h1{
    font-size: 1em;
    transition: all 0.5s ease;
  }
  .RoomsTabHeader button{
    transition: all 0.5s ease;
    width: 20px;
    height: 20px;
  }
  .DeleteRoomBtn{
    width: 90px;
  }
  .RoomsTabHeader button img{
    transition: all 0.5s ease;
    width: 20px;
  }
  .LeaveRoomHeaderTab{
    height: 57px;
  }
  .ChatTabHeader h1{
    font-size: 1.3em;
  }
  .ChatTabHeader p{
    font-size: 1em;
  }
}
@media (max-width: 790px){
  .RoomCreationPopUpHeader h2{
    font-size: .7em;
  }
  .RoomCreationPopUpHeader button{
    margin-left: 150px;
  }
  .TextMSG p{
    font-size: .8em;
  }
  .PrivateRoomPopUpHeader button{
    margin-left: 165px;
  }
  .PrivateRoomPopUpEnterPass input{
    width: 170px;
  }
  .PrivateRoomPopUpHeader h2{
    font-size: .6em;
  }
}
@media (max-width: 695px){
  .settingsusername h2{
    font-size: 1em;
  }
  .settingsusermail h2{
    font-size: 1em;
    word-break: break-all;
  }
}
@media (max-width: 605px){
  .SideTab {
    gap: 89.5%;
    transition: all 0.5s ease;
  }
  .SideTab button{
    width: 15px;
    height: 15px;
    transition: all 0.5s ease;
  }
  .SideTab button img{
    transition: all 0.5s ease;
    width: 20px;
  }
}
@media (max-width: 650px) {
  .RoomListInsideDivName h2{
    transition: all 0.5s ease;
    font-size: .8em;
    font-weight: bolder;
  }
  .RoomListInsideDivIconDiv img{
    width: 20px;
  }
  .LeaveRoomButtonSideTab{
    font-size: .7em;
  }
  .DeleteRoomBtn{
    width: 80px;
  }
}